@media (max-width: 668px) {
  .photo_auth {
    display: none;
  }
  .choice .block {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr !important;
    gap: 70px;
  }
  .form .block {
    display: grid;
    grid-template-columns: 1fr !important;
    gap: 70px;
  }
  .authentication-css {
    display: block !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
  }
  .authentication-grid {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr !important;
    gap: 20px;
  }
  .authentication-css img {
    width: 125px;
  }
  .none-img {
    display: none;
  }
  .form .login_form_head .text_center {
    font-size: 20px !important;
    font-weight: 700 !important;
    color: var(--black);
  }
  .form .login_form_head {
    display: grid;
    grid-template-columns: 1fr !important;
    margin-bottom: 15px;
  }
  .block-img-left {
    width: 45px !important;
    height: 45px !important;
  }
  .with {
    width: 45px !important;
    height: 45px !important;
  }
}
.authentication-css {
  display: none;
}
