.cryptocurrencies {
  width: 100%;
  padding-top: 30px;
  background: #f5f7fa;
  padding-bottom: 140px;
}

.cryptocurrencies .cryptocurrencies_block {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.cryptocurrencies .cryptocurrencies_box {
  width: 100%;
  height: 80px;
  padding: 10px 20px;
  border-radius: 8px;
  background: var(--white);
  display: flex;
  align-items: center;
  gap: 14px;
  justify-content: center;
  border: 2px solid transparent;
  transition: 0.3s ease;
  cursor: pointer;
  text-decoration: none;
}
.cryptocurrencies .cryptocurrencies_box img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}

.cryptocurrencies .cryptocurrencies_box:hover {
  border: 2px solid var(--blue);
}

.cryptocurrencies .cryptocurrencies_box .text {
  font-size: 22px;
  font-weight: 500;
  color: var(--black);
}

@media screen and (max-width: 700px) {
  .cryptocurrencies .cryptocurrencies_block {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .cryptocurrencies .cryptocurrencies_box {
    height: 60px;
    justify-content: flex-start;
  }
  .cryptocurrencies .cryptocurrencies_box .text {
    font-size: 18px;
    font-weight: 500;
    color: var(--black);
  }
  .cryptocurrencies .cryptocurrencies_box img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
  }
}

@media screen and (max-width: 415px) {
  .cryptocurrencies .cryptocurrencies_block {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
