.header {
  width: 100%;
  box-shadow: 0px 4px 25px rgba(101, 109, 125, 0.1);
  position: relative;
}
.header.active {
  width: 100%;
  box-shadow: 0px 4px 25px rgba(101, 109, 125, 0.1);
  position: relative;
  z-index: 9999;
}

.header .head {
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .center {
  display: flex;
  align-items: center;
  gap: 40px;
}

.header .link {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: var(--gray);
  text-decoration: none;
}

.header .link.register {
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 600;
  color: var(--white);
  background: var(--blue);
  border: none;
  margin-left: 25px;
}

.header .flex {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.header .flex a {
  text-decoration: none;
}

.header .button_form.login {
  color: var(--gray);
  font-weight: 500;
  background: white;
  box-shadow: none;
}

.header .favorites {
  border: 1px solid var(--gray_w);
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  color: var(--black_w);
  border-radius: 8px;
  padding: 0 14px;
  outline: none;
  background: #fff;
  cursor: pointer;
}

.header .user {
  width: 45px;
  height: 45px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.header .head-mob {
  display: none;
}

@media screen and (max-width: 668px) {
  .header .head {
    display: none;
  }
  .header .head-mob {
    width: 100%;
    height: 65px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .burger-block {
    background: var(--valuta-, rgba(245, 247, 250, 1));
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .burger-flex {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .burger-menu {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    z-index: 99;
  }
  .flex-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .burger-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 60%;
    height: 100%;
    background: #ffffff;
    padding: 20px;
  }
  .header .burger-left .center {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 25px;
    margin: 50px 0 0 0;
  }
  .header .burger-left .flex {
    display: block;
    gap: 0;
    margin: 25px 0 0;
  }
  .header .favorites {
    width: 80%;
  }
  .burger-close {
    float: right;
    cursor: pointer;
  }
}
