.table {
  margin-top: 0 !important;
  background: transparent !important;
  --bs-table-bg: transparent !important;
  vertical-align: 0 !important;
}
.table > :not(caption) > * > * {
  border-bottom-width: 0 !important;
}
.nav .slider_box {
  width: 100%;
  height: 240px;
}
.nav .slider_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.nav .nav_main {
  width: 100%;
  min-height: 440px;
  background: var(--valuta-, rgba(245, 247, 250, 1));
  border-radius: 20px;
  padding: 20px 20px 0 !important;
  margin: 0 0 130px 0 !important;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 20px;
}
.nav a {
  text-decoration: none;
}

.nav .title {
  font-size: 14px;
  font-weight: 700;
  color: var(--valuta-, rgba(28, 28, 28, 1));
}
.nav .title-1 {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray);
}
.nav .name {
  font-size: 12px;
  font-weight: 600;
  color: var(--gray);
}

.nav .first .images-img {
  width: 35px;
  height: 35px;
}
.nav .first .images-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.nav .first .crypto-name {
  font-size: 14px;
  font-weight: 600;
  color: var(--gray);
}
.nav .number {
  font-size: 16px;
  color: var(--valuta-, rgba(49, 49, 49, 1));
  font-weight: 600;
}

.rec {
  width: 324px;
  height: 400px;
}

.rec .slider_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 16px;
}

.nav .bottom-line {
  border-top: 1px solid rgba(215, 215, 215, 1) !important;
}
.blue {
  color: #0f7ffc !important;
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .nav .nav_main {
    padding: 20px !important;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
  }
}

.slick-initialized .slick-slide:focus {
  outline: none !important;
  border: none !important;
  -webkit-transform: none !important;
}

.nav .best-course {
  display: none;
}

@media (max-width: 970px) {
  .nav .nav_main {
    display: grid;
    grid-template-columns: 1fr;
  }
  .nav .nav_main .rec {
    width: 100%;
    height: 400px;
    margin: 20px 0 0 0;
  }
}

@media screen and (max-width: 840px) {
  .nav .average-course {
    display: none;
  }
  .nav .link-a {
    text-decoration: none;
  }
  .nav .best-course {
    display: block;
  }
}

@media (max-width: 640px) {
  .nav .nav_box_s .last_e {
    display: none;
  }
  .nav .last_e {
    display: none;
  }

  .nav .nav_main .nav_box {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 30px;
  }
  .nav .none_buy_sell {
    display: none;
  }
  .nav .last {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .nav .span {
    display: none;
  }
  .nav .text {
    display: none;
  }
}

@media (max-width: 480px) {
  .nav .nav_main .nav_box .best {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: var(--valuta-, rgba(24, 86, 205, 1));
    white-space: nowrap;
    margin: 0 auto !important;
  }

  .nav .nav_main .nav_box .title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: var(--valuta-, rgba(28, 28, 28, 1));
    white-space: nowrap;
    margin: 0 auto !important;
  }
  .nav .last_one {
    display: flex;
    position: relative;
  }
  .nav .first .name {
    font-size: 16px;
    font-weight: 600;
    color: var(--gray);
  }
  .nav .number {
    font-size: 16px;
  }
  .nav .best_line {
    display: flex;
    position: relative;
  }
  .nav .best_line::before {
    content: "";
    width: 100%;
    height: 4px;
    border-radius: 50px;
    background: var(--valuta-, rgba(24, 86, 205, 1));
    position: absolute;
    left: 0;
    bottom: -10px;
  }
  .nav .nav_main .nav_box .link {
    display: none;
  }
  .nav .last_bets_one {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  .nav .last_s {
    display: flex;
  }
  .nav .last {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 !important;
  }
}

@media (max-width: 460px) {
  .nav .last_bets {
    display: none;
  }
  .nav .buy_sell {
    display: grid;
    grid-template-columns: 1.3fr 2fr;
    gap: 13px;
    margin: 0 0 10px 0;
  }
  .nav .buy-sell-one-tewos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  .nav .nav_box_s {
    display: grid;
    grid-template-columns: 1.3fr 2fr;
    gap: 13px;
    align-items: center;
    margin: 0 0 15px 0;
  }
}
