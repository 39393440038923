.choice {
  padding-bottom: 140px;
}

.choice .block {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;
}

.choice .choice_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.choice .title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: var(--black);
  margin-bottom: 30px;
}

.choice .btn {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  background: var(--gray_ww);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  text-decoration: none;
  margin-bottom: 12px;
  border: 2px solid var(--white);
  transition: 0.3s ease;
}

.choice .btn:hover {
  border: 2px solid var(--blue);
}
