.post_application {
  padding: 30px;
  padding-bottom: 140px;
  background: var(--gray_ww);
}

.post_application h5 {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: var(--black_w);
  margin-bottom: 30px;
}

.post_application .white_block {
  padding: 30px;
}

.post_application .grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.post_application .back {
  cursor: pointer;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .post_application .grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }
  .post_application {
    padding: 30px 0;
  }
}
