@import './adaptive.css';

@font-face {
  font-family: Inter;
  src: url("../src/fonts/Inter-Black.ttf");
  font-weight: 100;
}

@font-face {
  font-family: Inter;
  src: url("../src/fonts/Inter-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: Inter;
  src: url("../src/fonts/Inter-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url("../src/fonts/Inter-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url("../src/fonts/Inter-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url("../src/fonts/Inter-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url("../src/fonts/Inter-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url("../src/fonts/Inter-ExtraBold.ttf");
  font-weight: 800;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}

.container {
  margin: 0 auto;
  max-width: 1140px;
  width: 94%;
}

:root {
  --white: #fff;
  --black: #000;
  --black_w: #313131;
  --blue: #1856cd;
  --gray: #8c8c8c;
  --gray_w: #d8d8d8;
  --gray_ww: #f5f7fa;
  --gray_g: #afafaf;
  --red: #EE0404;
}
p{
  padding: 0 !important;
  margin: 0 !important;
}