.form .text_gray {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  color: var(--gray);
  text-align: center;
}

.form .absolute {
  cursor: pointer;
  position: absolute;
  top: 60px;
  left: 0;
}
