.form .re_send {
  font-size: 14px;
  font-weight: 400;
  color: var(--blue);
  text-align: center;
  cursor: pointer;
}

.form .text_gray {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  color: var(--gray);
  text-align: center;
  margin-bottom: 24px;
}

.form .absolute {
  cursor: pointer;
  position: absolute;
  top: 60px;
  left: 0;
}
