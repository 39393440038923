.change {
  width: 100%;
}

.change .link {
  font-weight: 500;
  color: var(--blue);
}

.change .category {
  margin: 20px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.change .category_box {
  font-size: 16px;
  font-weight: 500;
  color: var(--gray);
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  white-space: nowrap;
}

.change .category_box.active {
  color: var(--white);
  background: var(--blue);
}

.change .stacks_block {
  border-radius: 20px;
  background: var(--gray_ww);
  padding: 30px;
}

.change .not_chart {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.change .two-stack {
  display: none;
}

@media screen and (max-width: 600px) {
  .change .stacks_block {
    padding: 30px 0;
  }
  .change .one-stack {
    display: none;
  }
  .change .two-stack {
    display: block;
  }
  .change .category {
    margin: 40px 0 20px 0;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: scroll;
    width: 100%;
  }
  .change .category::-webkit-scrollbar-track {
    -webkit-box-shadow: transparent;
    border-radius: 0;
    background-color: transparent;
  }

  .change .category::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  .change .category::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: transparent;
    background-color: transparent;
  }
}
