.applications_details {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 140px;
  background: var(--gray_ww);
}

.applications_details .white_block {
  padding: 20px;
  width: 100%;
  border-radius: 14px;
  background: #fff;
}

.applications_details .between-one {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.applications_details .flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.applications_details .image {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: covre;
  object-position: center;
}

.applications_details h2 {
  font-size: 20px;
  font-weight: 600;
  color: var(--black_w);
  margin-bottom: 16px;
}

.applications_details .title {
  font-size: 16px;
  font-weight: 600;
  color: var(--blue);
  text-decoration: underline;
  margin-bottom: 10px;
}

.applications_details .white_block_two p {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray);
}

.applications_details .text.blue {
  color: var(--blue);
  cursor: pointer;
}

.applications_details .grid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.applications_details .column {
  display: flex;
  flex-direction: column;
}

.applications_details .texting {
  padding: 10px;
  border-radius: 6px;
  background: var(--gray_ww);
}

.applications_details .texting_sum {
  padding: 10px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 24px;
  font-weight: 500;
  color: var(--black_w);
  border-radius: 8px;
  background: var(--gray_ww);
}

.applications_details .valuta {
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 400;
  color: var(--blue);
  background: #1856cd1a;
  border-radius: 6px;
}

.applications_details .array {
  width: 100%;
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.applications_details .array_box {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.applications_details .array_box .text {
  font-size: 16px;
  font-weight: 500;
  color: var(--black_w);
}

.applications_details .state {
  margin: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--gray);
  padding: 3px;
}

.applications_details .state.active {
  border: 2px solid var(--blue);
}

.applications_details .circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--blue);
}

@media screen and (max-width: 640px) {
  .applications_details .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

@media screen and (max-width: 540px) {
  .applications_details .between-one {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

@media screen and (max-width: 400px) {
  .applications_details .flex-one {
    display: grid;
    grid-template-columns: 1fr ;
    gap: 0;
  }
}
