.main_news {
  margin-top: 130px;
  width: 100%;
}

.main_news .main_news_block {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 40px;
}

.main_news .main_news_box {
  display: grid;
  grid-template-columns: 170px 1fr;
  gap: 15px;
  height: 160px;
  cursor: pointer;
}
.main_news .block_img {
  width: 170px;
  height: 160px;
}
.main_news .main_news_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 14px;
  object-position: center;
}

.main_news .main_news_box .flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0 !important;
}

.main_news .main_news_box .title {
  font-size: 16px;
  font-weight: 700;
  color: var(--blue);
  text-decoration: underline;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main_news .main_news_box .text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--black_w);
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin: 0 0 15px 0 !important;
}

.main_news .date_and_link {
  display: flex;
  gap: 14px;
}

.main_news .main_news_box .date {
  font-size: 14px;
  font-weight: 300;
  color: var(--gray);
}

.main_news .main_news_box .link {
  font-size: 14px;
  font-weight: 300;
  color: var(--blue);
}

.main_news .button_form.news-ones {
  margin: 0 auto;
  margin-top: 40px;
  width: 150px;
  padding: 10px 20px;
  background: transparent;
  border: 1px solid var(--blue);
  color: var(--blue);
}
.main_news .main_news_block a {
  text-decoration: none;
}
@media (max-width: 600px) {
  .main_news .main_news_block {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    column-gap: 20px;
    row-gap: 0;
  }
  .main_news .block_img {
    width: 125px;
    height: 125px;
  }
  .main_news .main_news_box {
    display: grid;
    grid-template-columns: 130px 1fr;
    height: 124px;
    cursor: pointer;
  }
  .main_news .main_news_box .title {
    height: 20px;
    font-size: 14px;
  }
  .main_news .main_news_box .text {
    font-size: 12px;
  }
  .main_news .main_news_block a {
    text-decoration: none;
    margin: 0 0 25px 0;
  }
  .main_news .button_form.news-ones {
    margin-top: 15px;
  }
}
