/* Auth */

.label_form {
  color: var(--gray);
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.input_form {
  width: 100%;
  height: 45px;
  background: var(--grayW);
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
  border-radius: 8px;
  padding: 13px 16px;
  background: #f5f7fa;
  border: 1px solid var(--white);
}

.input_form.disablet {
  background: #d8d8d8;
  color: var(--gray);
}

.input_form::placeholder {
  color: #afafaf;
}

.input_form:focus {
  border: 1px solid var(--blue);
}

.button_form {
  border: none;
  width: 100%;
  height: 45px;
  padding: 0 20px;
  border-radius: 8px;
  background: var(--blue);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  color: #fdfdfd;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.button_form:active {
  filter: brightness(90%);
}

.button_form.white {
  color: var(--blue);
  background: transparent;
  border: 1px solid var(--blue);
  box-shadow: none;
}

.input_box {
  width: 100%;
  height: 96px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.span-icon {
  z-index: 1;
  position: absolute;
  top: 38px;
  right: 16px;
  font-size: 20px;
  color: var(--gray);
  cursor: pointer;
}

.form {
  position: relative;
  padding-bottom: 140px;
}

.form .login_form_head {
  display: grid;
  grid-template-columns: 45px 1fr 45px;
  margin-bottom: 15px;
}

.form .login_form_head img {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.form .login_form_head .text_center {
  font-size: 24px;
  font-weight: 600;
  color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo_auth {
  margin-top: 22px;
  width: 100%;
  height: 85vh;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
}

.form .block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;
}

.form .block form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form .p_content {
  text-align: center;
}
.red {
  color: red;
  font-size: 12px;
  font-weight: 400;
}

/* All */

.title_h1 {
  font-size: 30px;
  font-weight: 500;
  color: var(--black);
  display: flex;
  align-items: center;
}

.text_p {
  margin-top: 14px;
  font-size: 16px;
  font-weight: 400;
  color: var(--gray);
}

.box_text {
  font-size: 16px;
  font-weight: 400;
  color: #1856cd;
  border-radius: 6px;
  padding: 8px 16px;
  background: #1856cd1a;
  margin-left: 20px;
}

@media (max-width: 600px) {
  .title_h1 {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: var(--black);
    text-align: start;
  }

  .text_p {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: var(--gray);
  }

  .box_text {
    font-size: 14px;
    font-weight: 400;
    color: #1856cd;
    border-radius: 6px;
    padding: 6px 10px;
    background: #1856cd1a;
    margin-left: 15px;
  }
}

.white_block {
  padding: 20px;
  width: 100%;
  border-radius: 14px;
  background: #fff;
}

.between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.between.al {
  align-items: start;
}

.flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.text_g {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray);
}
