.news {
  padding-bottom: 140px;
  margin: 0 !important;
}

.news .news_block {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 30px;
}

.news .news_box {
  display: grid;
  grid-template-columns: 170px 1fr;
  align-items: center;
  gap: 20px;
  height: 160px;
  cursor: pointer;
}
.news .block_img {
  width: 170px;
  height: 160px;
}

.news .news_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 14px;
  object-position: center;
}

.news .news_box .flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0 !important;
}

.news .news_box .title {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: var(--blue);
  text-decoration: underline;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news .news_box .text {
  font-size: 14px;
  font-weight: 400;
  color: var(--black_w);
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.news .date_and_link {
  display: flex;
  gap: 14px;
}

.news .news_box .date {
  font-size: 14px;
  font-weight: 300;
  color: var(--gray);
}

.news .news_box .link {
  font-size: 14px;
  font-weight: 300;
  color: var(--blue);
}

.news .button_form.news {
  margin: 0 auto;
  margin-top: 40px;
  width: 150px;
  padding: 10px 20px;
  background: transparent;
  border: 1px solid var(--blue);
  color: var(--blue);
}

@media (max-width: 600px) {
  .news .news_block {
    margin-top: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    column-gap: 20px;
    row-gap: 30px;
  }
  .news .block_img {
    width: 125px;
    height: 125px;
  }
  .news .news_box {
    display: grid;
    grid-template-columns: 130px 1fr;
    align-items: center;
    height: 160px;
    cursor: pointer;
  }
  .news .news_box .title {
    font-size: 14px;
    height: 20px;
  }
  .news .news_box .text {
    font-size: 12px;
  }
}
