.exchange_bureaus {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.exchange_bureaus .map-container {
  position: sticky;
  top: 0;
  height: 100vh;
}

.exchange_bureaus .bureaus_block {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: var(--gray_ww);
}

.exchange_bureaus .bureaus_box {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
  margin-bottom: 10px;
  background: var(--white);
  border-radius: 16px;
  cursor: pointer;
}
.exchange_bureaus .image_logo_bureaus {
  width: 200px;
  height: 100px;
}

.exchange_bureaus .bureaus_box img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}

.exchange_bureaus .bureaus_box .flex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start !important;
}

.exchange_bureaus .bureaus_box .title {
  width: 250px;
  font-size: 18px;
  font-weight: 600;
  color: var(--blue);
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.exchange_bureaus .flex-icon-dec {
  display: flex;
  align-items: center;
  gap: 7px;
}
.exchange_bureaus .flex-icon-dec img {
  width: 24px;
}
.exchange_bureaus .bureaus_box .text {
  font-size: 14px;
  font-weight: 400;
  color: var(--black_w);
  display: flex;
  align-items: center;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.exchange_bureaus .text .icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  color: var(--blue);
}

.exchange-bureaus-map .relative {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.exchange-bureaus-map .relative input {
  height: 45px;
  outline: none;
  border: none;
  width: 100%;
  padding: 10px 16px;
  padding-left: 46px;
  background: var(--white);
  border-radius: 8px;
}

.exchange_bureaus .relative input::placeholder {
  color: var(--gray_g);
}

.exchange-bureaus-map .search {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 16px;
}

.exchange-bureaus-map .one {
  display: none;
}

@media screen and (max-width: 600px) {
  .exchange-bureaus-map {
    width: 100%;
    min-height: 100vh;
    padding: 20px;
    background: var(--gray_ww);
  }
  .exchange_bureaus {
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
  }
  .exchange_bureaus .image_logo_bureaus {
    width: 140px;
    height: 100px;
  }
  .exchange_bureaus .bureaus_box .title {
    width: 100%;
    font-size: 17px;
    white-space: wrap;
  }

  .exchange-bureaus-map .one {
    display: block;
  }
  .exchange-bureaus-map .twos-er {
    display: none;
  }
  .exchange_bureaus .map-container {
    position: relative;
    top: 0;
    height: 50vh;
  }
  .exchange_bureaus .bureaus_block {
    padding: 20px 0 0 0;
  }
}
