.cabinet {
  width: 100%;
  padding: 30px;
  padding-bottom: 140px;
  background: var(--gray_ww);
}

.cabinet h1 {
  font-size: 24px;
  font-weight: 600;
  color: var(--black_w);
  margin-bottom: 30px;
}

.cabinet .title {
  font-size: 22px;
  font-weight: 600;
  color: var(--black_w);
}

.cabinet .col {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cabinet .text {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray);
}

.cabinet .texting {
  padding: 10px;
  border-radius: 6px;
  background: var(--gray_ww);
  border: none;
}

.cabinet .grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2.5fr;
}

.cabinet .applications_block {
  margin-top: 20px;
  border-radius: 14px;
  padding: 20px;
  background: var(--gray_ww);
}

.cabinet .text.red {
  color: var(--red);
  cursor: pointer;
}

.cabinet .text.blue {
  color: var(--blue);
  cursor: pointer;
}

.cabinet .sell_buy {
  padding: 10px 16px;
  border-radius: 6px;
  color: var(--blue);
  background: #1856cd1a;
}

.cabinet h3 {
  font-size: 20px;
  font-weight: 600;
  color: var(--black_w);
  margin-bottom: 16px;
}

.cabinet .grid_two {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.cabinet .column {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.cabinet .deleted {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.cabinet .form_password {
  margin-top: 30px;
  width: 100%;
}

.cabinet .btns {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.cabinet .btns .button_form.off {
  background: var(--gray);
}

.cabinet .btns .button_form.on {
  background: var(--red);
}

.cabinet .white_block p {
  font-size: 14px;
  font-weight: 400;
  color: var(--valuta-, rgba(49, 49, 49, 1));
}
.cabinet .flex-delte {
  display: none;
}
.cabinet .flex-delte-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

@media screen and (max-width: 768px) {
  .cabinet .grid {
    display: grid;
    grid-template-columns: 1fr;
  }
  .cabinet .col {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin: 20px 0 0 0;
  }
  .cabinet .grid_two {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .cabinet .flex-delte {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  .cabinet .flex-delte-one {
    display: none;
  }
  .cabinet {
    padding: 30px 0;
  }
}

@media screen and (max-width: 510px) {
  .between.al {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .between.al button {
    margin: 10px 0 0 0;
  }
  .cabinet .title {
    font-size: 18px;
    font-weight: 700;
  }
  .cabinet .form_password {
    margin-top: 30px;
    width: 100%;
  }
}
